import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"

import { Layout } from "../components/Layout"
import { HeroInfo } from "../components/Miami/Hero/HeroInfo"
import { HeroGallery } from "../components/Miami/Hero/HeroGallery"
import { About } from "../components/Miami/About/About"
import { Testimonial } from "../components/Miami/Testimonial/Testimonial"
import { FindTheCompany } from "../components/Miami/FindTheCompany/FindTheCompany"
import { LookingFor } from "../components/Miami/LookingFor/LookingFor"
import { Cta } from "../components/Miami/Cta/Cta"
import { CompanyModal } from "../components/Companies/CompanyModal"
import { ModalUser } from "../components/Team/ModalUser"

export default function MiamiLP({ data }) {
  const {
    site: {
      siteMetadata: { title },
    },
    globalMetaData,
    barData,
    headerData,
    footerData,
    MiamiLp: {
      metaData,
      topHeader,
      topButtonToggle,
      topButton,
      topSectionGallery,
      aboutUsTitle,
      aboutUsRightColumn: { aboutUsRightColumn },
      aboutUsLeftColumn: { aboutUsLeftColumn },
      aboutReferenceCompanies,
      aboutReferenceMembers,
      testimonialText,
      testimonialAuthorName,
      testimonialAuthorPosition,
      testimonialImage,
      findTheCompanyTitle,
      findTheCompanyText,
      findTheCompanyTeasers,
      whatAreWeLookingForTitle,
      whatAreWeLookingForText,
      whatAreWeLookingForTeasers,
      ctaTitle,
      ctaButtonTitle,
      tickerDuration,
      tickerData,
    },
  } = data

  const [activeCompany, setActiveCompany] = useState(null)
  const [activeMember, setActiveMember] = useState(null)
  const modalDataArray = [...aboutReferenceCompanies, ...aboutReferenceMembers]

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.documentElement.scrollTop = 0

      const hash = window.location.hash
      if (hash) {
        const modalElSlug = hash.slice(1)

        const actvCompany = aboutReferenceCompanies.find(
          el => el.slug === modalElSlug
        )

        if (actvCompany) {
          setActiveCompany(actvCompany)
        } else {
          const actvMember = aboutReferenceMembers.find(
            el => el.slug === modalElSlug
          )

          if (actvMember) {
            setActiveMember(actvMember)
          }
        }
      }
    }
  }, [])

  const getModalDataBySlug = slug => {
    return modalDataArray.find(el => el.slug === slug)
  }

  const onCompanyLinkClick = company => {
    const modalData = getModalDataBySlug(company)
    setActiveCompany(modalData)
  }

  const onMemberLinkClick = member => {
    const modalData = getModalDataBySlug(member)
    setActiveMember(modalData)
  }

  const Modals = () => {
    if (activeCompany) {
      navigate(`#${activeCompany.slug}`)
      return (
        <CompanyModal
          {...activeCompany}
          onClose={() => {
            setActiveCompany(null)
            changeUrlCLose()
          }}
        />
      )
    }
    if (activeMember) {
      navigate(`#${activeMember.slug}`)
      return (
        <ModalUser
          {...activeMember}
          onClose={() => {
            setActiveMember(null)
            changeUrlCLose()
          }}
        />
      )
    }
    return null
  }

  const changeUrlCLose = () => {
    if (typeof window !== "undefined") navigate(window.location.pathname)
  }

  return (
    <Layout
      barData={barData}
      headerData={headerData}
      footerData={footerData}
      ctaDisplay={false}
      tickerDuration={tickerDuration}
      tickerData={tickerData}
      pageTitle="Atomic Miami"
      title={title}
      globalMetaData={globalMetaData}
      metaData={metaData}
    >
      <div className="miami-hero">
        <HeroInfo
          topHeader={topHeader}
          topButtonToggle={topButtonToggle}
          topButton={topButton}
        />
        <HeroGallery topSectionGallery={topSectionGallery} />
      </div>
      <About
        aboutUsTitle={aboutUsTitle}
        aboutUsRightColumn={aboutUsRightColumn}
        aboutUsLeftColumn={aboutUsLeftColumn}
        setActiveCompany={onCompanyLinkClick}
        setActiveMember={onMemberLinkClick}
      />
      <Testimonial
        testimonialText={testimonialText}
        testimonialAuthorName={testimonialAuthorName}
        testimonialAuthorPosition={testimonialAuthorPosition}
        testimonialImage={testimonialImage}
      />{" "}
      <FindTheCompany
        findTheCompanyTitle={findTheCompanyTitle}
        findTheCompanyText={findTheCompanyText}
        findTheCompanyTeasers={findTheCompanyTeasers}
      />{" "}
      <LookingFor
        whatAreWeLookingForTitle={whatAreWeLookingForTitle}
        whatAreWeLookingForText={whatAreWeLookingForText}
        whatAreWeLookingForTeasers={whatAreWeLookingForTeasers}
      />
      <Cta ctaTitle={ctaTitle} ctaButtonTitle={ctaButtonTitle} />
      <Modals />
    </Layout>
  )
}

export const pageQuery = graphql`
  query MiamiLPQuery {
    site {
      siteMetadata {
        title
      }
    }
    globalMetaData: contentfulGlobalMetaData {
      desc {
        desc
      }
      keywords {
        keywords
      }
      image {
        file {
          src: url
        }
      }
    }
    barData: contentfulSectionAnnouncementBar {
      visible
      text
      link
    }
    headerData: contentfulSectionHeader {
      logo {
        file {
          src: url
        }
        alt: title
      }
      logoMobile {
        file {
          src: url
        }
        alt: title
      }
    }
    footerData: contentfulSectionFooter {
      email
      secondEmail
      social {
        icon {
          file {
            src: url
          }
          alt: title
        }
        link
      }
      newsletterTitle
      logo {
        file {
          src: url
        }
        alt: title
      }
      copyright
    }
    MiamiLp: contentfulPageMiamiLp {
      metaData {
        title
        desc {
          desc
        }
        keywords {
          keywords
        }
        image {
          file {
            src: url
          }
        }
      }
      topButtonToggle
      topButton
      topHeader {
        title
        desc {
          text: desc
        }
      }
      tickerDuration {
        duration
      }
      tickerData: ticker {
        text {
          text
        }
      }
      topSectionGallery {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      aboutUsTitle
      aboutUsRightColumn {
        aboutUsRightColumn
      }
      aboutUsLeftColumn {
        aboutUsLeftColumn
      }
      aboutReferenceCompanies {
        slug
        name
        desc {
          text: desc
        }
        logoBlack {
          file {
            src: url
          }
          alt: title
        }
        title
        investors {
          name
        }
        links {
          link
        }
        images {
          alt: title
          fluid(maxWidth: 320) {
            ...GatsbyContentfulFluid
          }
        }
      }
      aboutReferenceMembers {
        slug
        smallPhoto {
          fluid(maxWidth: 317) {
            ...GatsbyContentfulFluid
          }
          alt: title
        }
        position
        name
        largePhoto {
          fluid(maxWidth: 760) {
            ...GatsbyContentfulFluid
          }
          title
        }
        social {
          icon {
            file {
              src: url
            }
            alt: title
          }
          link
        }
        desc {
          text: desc
        }
        faq {
          title
          text {
            text
          }
        }
      }
      testimonialText
      testimonialAuthorName
      testimonialAuthorPosition
      testimonialImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      findTheCompanyTitle
      findTheCompanyText
      findTheCompanyTeasers {
        title
        description
      }
      whatAreWeLookingForTitle
      whatAreWeLookingForText
      whatAreWeLookingForTeasers {
        text
      }
      ctaTitle
      ctaButtonTitle
    }
  }
`
