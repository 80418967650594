import React from "react"
import { Image } from "../../Image"

export function HeroGallery({ topSectionGallery }) {
  return (
    <div className="hero-gallery">
      <div className="hero-gallery-wrapper">
        {topSectionGallery?.map((photo, i) => (
          <div key={i} className="hero-gallery-item">
            <Image className="img" image={photo} />
          </div>
        ))}
      </div>
    </div>
  )
}
