import React from "react"
import { Image } from "../../Image"

export function Testimonial({
  testimonialText,
  testimonialAuthorName,
  testimonialAuthorPosition,
  testimonialImage,
}) {
  return (
    <div className="miami-testimonial">
      <div className="testimonial__content">
        <div className="testimonial__content-inner">
          <span className="quote-symbol">“</span>
          <p className="testimonial__text">{testimonialText}”</p>
          <p className="testimonial__author">
            {testimonialAuthorName}
            <br />
            {testimonialAuthorPosition}
          </p>
        </div>
      </div>
      <div className="testimonial__image">
        <Image className="img" image={testimonialImage} />
      </div>
    </div>
  )
}
