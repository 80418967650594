import React from "react"

export function FindTheCompany({
  findTheCompanyTitle,
  findTheCompanyText,
  findTheCompanyTeasers,
}) {
  return (
    <div className="find-company">
      <div className="find-company__inner">
        <h2
          className="find-company__title"
          dangerouslySetInnerHTML={{
            __html: findTheCompanyTitle,
          }}
        />
        <div
          className="find-company__content"
          dangerouslySetInnerHTML={{
            __html: findTheCompanyText,
          }}
        />
        <div className="find-company__teasers">
          {findTheCompanyTeasers?.map(({ title, description }, i) => (
            <div key={i} className="find-company__teaser">
              <h4
                className="teaser__title"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
              <p
                className="teaser__description"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
