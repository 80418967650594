import React, { useContext } from "react"
import childrenContext from "../../childrenContext"

export function HeroInfo({ topHeader, topButtonToggle, topButton }) {
  const context = useContext(childrenContext)
  const {
    title,
    desc: { text },
  } = topHeader
  return (
    <div className="content miami-lp">
      <div className="container">
        <div className="title">
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p>{text}</p>
          {topButtonToggle && (
            <button
              style={{ cursor: "pointer", outline: "none" }}
              onClick={() => context.openMenu()}
              className="btn btn-large btn-red"
            >
              {topButton}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
