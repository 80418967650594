import React from "react"
import parse, { domToReact } from "html-react-parser"

export function About({
  aboutUsTitle,
  aboutUsRightColumn,
  aboutUsLeftColumn,
  setActiveCompany,
  setActiveMember,
}) {
  const options = {
    replace: domNode => {
      if (
        domNode.type === "tag" &&
        domNode.name === "a" &&
        domNode.attribs.href.includes("#")
      ) {
        return (
          <a
            href={domNode.attribs.href}
            onClick={() => {
              if (domNode.attribs["data-type"] === "member") {
                setActiveMember(domNode.attribs.href.slice(1))
              } else if (domNode.attribs["data-type"] === "company") {
                setActiveCompany(domNode.attribs.href.slice(1))
              }
            }}
          >
            {domToReact(domNode.children)}
          </a>
        )
      }
    },
  }

  return (
    <div className="miami-about">
      <div className="miami-about__inner">
        <h2
          className="miami-about__title"
          dangerouslySetInnerHTML={{
            __html: aboutUsTitle,
          }}
        />
        <div className="miami-about__content">
          <div className="miami-about__content-column">
            {parse(aboutUsLeftColumn, options)}
          </div>
          <div className="miami-about__content-column">
            {parse(aboutUsRightColumn, options)}
          </div>
        </div>
      </div>
    </div>
  )
}
