import React from "react"

export function LookingFor({
  whatAreWeLookingForTitle,
  whatAreWeLookingForText,
  whatAreWeLookingForTeasers,
}) {
  return (
    <div className="miami-looking-for">
      <div className="miami-looking-for__inner">
        <h2
          className="miami-looking-for__title"
          dangerouslySetInnerHTML={{
            __html: whatAreWeLookingForTitle,
          }}
        />
        <div
          className="miami-looking-for__content"
          dangerouslySetInnerHTML={{
            __html: whatAreWeLookingForText,
          }}
        />
        <div className="miami-looking-for__teasers">
          {whatAreWeLookingForTeasers?.map(({ text }, i) => (
            <div key={i} className="miami-looking-for__teaser">
              <div className="teaser__text-wrapper">
                <p
                  className="teaser__text"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
