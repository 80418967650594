import React, { useContext } from "react"
import childrenContext from "../../childrenContext"

export function Cta({ ctaTitle, ctaButtonTitle }) {
  const context = useContext(childrenContext)
  return (
    <div className="miami-cta">
      <div className="miami-cta__inner">
        <h2
          className="miami-cta__title"
          dangerouslySetInnerHTML={{
            __html: ctaTitle,
          }}
        />
        <button
          style={{ cursor: "pointer", outline: "none" }}
          onClick={() => context.openMenu()}
          className="btn btn-large btn-red"
        >
          {ctaButtonTitle}
        </button>
      </div>
    </div>
  )
}
